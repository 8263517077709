import React, { memo } from "react"
import { useState } from "react"
import { Typography } from "antd"
import InfoModal from "../components/InfoModal"
import Img from "gatsby-image"
import { Link } from "gatsby"

const { Title } = Typography

const CaseStudeiesTopSection = ({ headerData, data }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    let project
    if (typeof window !== "undefined") {
        project = window.location.pathname.includes("/projects")
    }

    const backgroundImageClass = project
        ? "background-image-02"
        : "background-image"
    const headerContainerClass = project ? "header-container-color" : ""
    const bgImage = data.bgImages.childImageSharp.fluid.src
    return (
        <div
            className={`case-Study-with-Logo ${backgroundImageClass}`}
            style={{ color: "red", backgroundImage: `url(${bgImage})` }}
        >
            <div className="logo">
                <Link to="/" style={{ paddingLeft: "0%", marginTop: "65px" }}>
                    {project ? (
                        <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
                    ) : (
                        <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
                    )}
                </Link>
            </div>

            <div className="caseStudies-top-section">
                <div className={`headings-container ${headerContainerClass}`}>
                    <Title>{headerData.title}</Title>
                    <p>{headerData.peragraph}</p>
                </div>
                <Img fluid={data.HeaderImg.childImageSharp.fluid} />
                <button onClick={showModal}>Request for Demo</button>
                <InfoModal
                    companyIsEnabled
                    url="http://46.101.195.121:8066/email/ContactUs"
                    isModalVisible={isModalVisible}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                />
            </div>
        </div>
    )
}

export default memo(CaseStudeiesTopSection)
