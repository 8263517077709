import React, { memo } from "react"
import Img from "gatsby-image"
import IconsAdded from "./IconsAdded"
const ImageWithTextSection = ({ caseStudyData, dataFormImages,classText }) => {
    return (
        <div className="position-relative">
        <div className="images-display">
            {caseStudyData.data.map((d, index) => {
                return (
                    <>
                        {index % 2 === 0 || index === 0?
                        (
                            <div className={`ImageWithTextSection even ${classText||''}`}>
                                <Img
                                    fluid={
                                        dataFormImages[`img${index + 1}`]?.childImageSharp.fluid
                                    }
                                    style={{height:"400px"}}
                                />
                                <div>
                                    <h1>{d.heading}</h1>
                                    <p>{d.description}</p>
                                </div>
                            </div>
                        ) : (
                            <div className={`ImageWithTextSection odd ${classText||''}`}>
                                <div>
                                    <h1>{d.heading}</h1>
                                    <p>{d.description}</p>
                                </div>
                                <Img
                                    fluid={
                                        dataFormImages[`img${index + 1}`]?.childImageSharp.fluid
                                    }
                                    style={{height:"400px"}}
                                />
                            </div>
                        )}
                    </>
                )
            })}
        </div>
        <IconsAdded/>
        </div>
    )
}

export default memo(ImageWithTextSection)
