import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"
import News from "../../../components/News"
import ProductTopSection from "../../../components/ProductTopSelection"
import WhyChooseVisiomateforD365 from "../../../components/WhyChooseVisiomateforD365"
import Wethoughtyoumighthavequestions from "../../../components/Wethoughtyoumighthavequestions"
import CaseStudeiesSimplified from "../../../components/CaseStudeiesSimplified"
import headerImage from "../../../images/Donor/header.png"
import img1 from "../../../images/Donor/1.png"
import img2 from "../../../images/Donor/2.png"
import img3 from "../../../images/Donor/3.png"
import img4 from "../../../images/Donor/4.png"
import img5 from "../../../images/Donor/5.png"
import img6 from "../../../images/Donor/6.png"
import img12 from "../../../images/Donor/7.png"
import img13 from "../../../images/Donor/8.png"

import ImageWithTextSection from "../../../components/ImageWithTextSection"
import IconsAdded from "../../../components/IconsAdded"

const DnationCampaignManagement = ({ data }) => {
  return (
    <div className="CaseStudeies">
      <Layout pageTitle={"Visiomate - Donation Campaign Management"}
      descrip = {"Streamline your donation campaigns and maximize fundraising success. Our platform simplifies donor management, tracking, and reporting. Learn more!"}>
        <ProductTopSection
          headerData={{
            title:
              "Donor Relationship Management: a complete donor and donation management system.",
            peragraph:
              "Vital for nonprofits looking to establish long-lasting bonds with their benefactors, which will eventually boost support, fidelity, and the effect of their work.s",
            image: headerImage,
          }}
          data={data}
        />
        <div className="position-relative">
        <WhyChooseVisiomateforD365
          data={{
            title: "Why Choose Donor Relationship Management?",
            headings: [
              {
                heading: "Improved Donor Engagement",
                description:
                  "DRM permits more in-depth interaction and connection with the organization's goals and operations by enabling tailored and targeted contact with donors.",
              },
              {
                heading: "Enhanced Donor Retention",
                description:
                  "DRM assists in keeping current contributors and motivates them to support the organization over time by putting a heavy emphasis on developing great relationships.",
              },
              {
                heading: "Increased Fundraising Effectiveness",
                description:
                  "Higher response rates, and better fundraising outcomes are the results of more purposeful and well-informed fundraising efforts that stem from effective DRM methods.",
              },
              {
                heading: "Better Data Management",
                description:
                  "Donor data is gathered, sorted, and analyzed as part of DRM in order to produce insightful information that can enhance campaign targeting, optimize resource allocation, and guide decision-making.",
              },
              {
                heading: "Streamlined Operations",
                description:
                  "By streamlining administrative duties including gift processing, acknowledgment, and reporting, DRM procedures let staff members to concentrate more of their time and resources on activities that are vital to the organization's goal.",
              },
              {
                heading: "Enhanced Donor Trust and Satisfaction",
                description:
                  "DRM cultivates long-term donor loyalty and commitment to the organization by establishing trust and satisfaction through open communication, prompt acknowledgment, and efficient stewardship.",
              },
            ],
          }}
          dataFormImages={data}
        />
        <IconsAdded iconColor={"yellow"}/>
        </div>
        <ImageWithTextSection
          caseStudyData={{
            data: [
              {
                heading: "Donation Campaign Management",
                description:
                  "Setting objectives for the campaign, identifying target markets, developing persuasive marketing collateral, choosing funding sources, and monitoring campaign outcomes are all included.",
                image: img1,
              },
              {
                heading: "Segments & Tags",
                description:
                  "Donors can be categorized and arranged using segments and tags, which allows for more specialized and individualized interaction and communication tactics.",
                image: img2,
              },
              {
                heading: "Contacts & Donors",
                description:
                  "Contacts can be managed and used in campaigns for future donation requests. A contact can be converted to a donor once first donation is recorded.",
                image: img3,
              },
              {
                heading: "Dynamic Filters",
                description:
                  "With the help of these filters, users can hone in on and reduce the size of extensive datasets or content according to their own chosen parameters or criteria.",
                image: img4,
              },
              {
                heading: "Export to Excel & CSV",
                description:
                  "Users can utilize spreadsheet tools to manipulate, analyze, and visualize data by exporting it to Excel or CSV format. It enables additional reporting, data processing, and sharing.",
                image: img5,
              },
              {
                heading: "Rich Email Template",
                description:
                  "Sophisticated email designs that combine dynamic information, interactive aspects, and a variety of visual elements to produce email communications that are both compelling and engaging.",
                image: img6,
              },
              {
                heading: "Dashboards",
                description:
                  " Graphs, charts, and other visual elements make complex data more comprehensible, enabling users to quickly grasp trends, patterns, and outliers.",
                image: img12,
              },
              {
                heading: "Reporting & Analysis",
                description:
                  "Analytics help identify trends and patterns in donations, and donor behavior, allowing organizaitons to capitalize on opportunities and mitigate challenges.",
                image: img13,
              },
            ],
          }}
          dataFormImages={data}
        />
        <CaseStudeiesSimplified
          TextData={{
            title: "Simplified Steps to get you Started ",
            Data: [
              {
                peragraph: "Define Requirements",
                list: [
                  "Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.",
                  "List down the essential features and functionalities required.",
                ],
              },
              {
                peragraph: "Demo and Evaluation",
                list: [
                  "Schedule product demonstrations. ",
                  "Evaluate the software's usability, features, and performance. ",
                  "Engage key stakeholders in the evaluation process.",
                ],
              },
              {
                peragraph: "Scalability and Integration",
                list: [
                  "Identify the potential integrations required by the customer.",
                  "Plan the implementation of identified integrations.",
                ],
              },
              {
                peragraph: "UAT & User Feedback",
                list: [
                  "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                  "Customer’s insights can provide valuable perspectives on usability, functionality mismatch.",
                ],
              },
              {
                peragraph: "User Training",
                list: [
                  " User training is a critical component of successfully implementing the software solution.",
                  "Proper training ensures that users can effectively and efficiently utilize the software, maximizing its benefits.",
                ],
              },
              {
                peragraph: "Post-Implementation Support",
                list: [
                  "Provide ongoing support for users.",
                  "Address any post-implementation issues.",
                  "Evaluate and implement system enhancements based on feedback.",
                ],
              },
            ],
          }}
        />
        <Wethoughtyoumighthavequestions
          data={[
            {
              question:
                "What is a Donor Relationship Management Application? ",
              answer:
                "A Donation Management Application is a software tool designed to maintain the contact to reach out for donation requests. It allows to create the compaigns to reach out contacts through emails and SMS.",
            },
            {
              question:
                "Can a Donation Management Application handle online donations?",
              answer:
                "Yes, a donation link is shared via email which can be used to pay the donation online.",
            },
            {
              question:
                "How does donor management work in a Donation Management Application?",
              answer:
                "Donor management features enable organizations to maintain donor profiles, track donation history, segment donors based on various criteria, send personalized communications, and cultivate donor relationships.",
            },
            {
              question:
                "What types of reports can be generated with a Donation Management Application?",
              answer:
                "Reports can include donation summaries, donor contribution history, fundraising campaign performance, donor retention rates, financial statements, and compliance reports for regulatory purposes. Application provides and comprehensive reporting tool to create and save reports as per users need.",
            },
            {
              question:
                "Is a Donation Management Application customizable to fit different organization's needs?",
              answer:
                "Yes, many Donation Management Applications offer customization options to tailor the software to the specific requirements and workflows of different types of organizations, such as nonprofits, charities, churches, and foundations.",
            },
            {
              question:
                "Is training and support provided for using a Donation Management Application?",
              answer:
                "Yes, we offer training resources, user guides, tutorials, and customer support to help organizations effectively implement and utilize the software.",
            },
          ]}
          title="We thought you might have questions"
        />
        <News isAbout={true} career={true} />
      </Layout>
    </div>
  )
}

export default DnationCampaignManagement
export const query = graphql`
  query DonationImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HeaderImg: file(relativePath: { eq: "Donor/header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImages: file(relativePath: { eq: "prodbg-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "Donor/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "Donor/2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "Donor/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "Donor/4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "Donor/5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "Donor/6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "Donor/7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img8: file(relativePath: { eq: "Donor/8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
